import {
  cornerIllustration,
  footerIllustration,
  logo,
  triangle,
} from "./assets/icons";
import { motion } from "framer-motion";

function App() {
  return (
    <main className="bg-black">
      <section className="w-full h-screen relative">
        <motion.div
          initial={{
            width: "100%",
          }}
          animate={{
            width: "0%",
          }}
          transition={{
            delay: 0.2,
            ease: [0.83, 0, 0.17, 1],
            duration: 0.8,
          }}
          className="absolute h-full top-0 left-0 bg-green z-20"
        ></motion.div>
        <motion.div
          initial={{
            height: "100%",
          }}
          animate={{
            height: "0%",
          }}
          transition={{
            delay: 0.5,
            ease: [0.83, 0, 0.17, 1],
            duration: 0.8,
          }}
          className="absolute w-full top-0 left-0 bg-yellow z-10"
        ></motion.div>
        <div className="w-full h-full relative">
          <motion.img
            initial={{
              y: "-200%",
            }}
            animate={{
              y: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.9,
            }}
            style={{
              translateX: "-50%",
            }}
            src={logo}
            alt="logo"
            className="absolute left-1/2 top-12"
          />
          <motion.img
            initial={{
              x: "-100%",
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.4,
            }}
            src={cornerIllustration}
            alt="corner-illustration"
            className="hidden scrn-760:block absolute left-0"
          />
          <motion.img
            initial={{
              x: "100%",
            }}
            animate={{
              x: 0,
            }}
            transition={{
              duration: 0.8,
              delay: 1.4,
            }}
            src={footerIllustration}
            alt="footer-illustration"
            className="absolute right-0 bottom-0"
          />
          <div className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-[2rem] scrn-525:text-[3.25rem] uppercase px-5">
            <div className="flex flex-col items-end">
              <div className=" flex items-center gap-2 reveal-mask">
                {Array(6)
                  .fill(0)
                  .map((_, idx) => {
                    return (
                      <motion.img
                        src={triangle}
                        initial={{
                          y: "-110%",
                        }}
                        animate={{
                          y: 0,
                        }}
                        transition={{
                          duration: 0.5,
                          delay: 2.5 + idx * 0.1,
                        }}
                        alt="triangle"
                      />
                    );
                  })}
              </div>
              <h1 className="reveal-mask whitespace-nowrap">
                {"Coming Soon".split("").map((char, idx) => {
                  return (
                    <motion.span
                      initial={{
                        y: "100%",
                      }}
                      animate={{
                        y: 0,
                      }}
                      transition={{
                        duration: 0.5,
                        delay: 2.1 + idx * 0.05,
                      }}
                      key={idx}
                      className="inline-block"
                    >
                      {char === " " ? "\u00A0" : char}
                    </motion.span>
                  );
                })}
              </h1>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default App;
